import React from "react"

import styles from "./heading.module.css";
import {Link} from "gatsby";

const Heading = ({colour}) => {

    return (
        <div
        >
            <Link
                to="/"
                className={`${styles.heading} ${styles.largeHeading}`}
                style={{
                    color: colour
                }}
            >
                <span>Bodalla</span> <br/>
                <span
                    style={{
                        paddingLeft: '30px'
                    }}
                >Lavender
                </span><br/>
                <span
                    style={{
                        paddingLeft: '60px'
                    }}
                >Shop</span>
            </Link>
        </div>
    )
};

export default Heading;
