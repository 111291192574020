import React from "react"

import Layout, {designSystem} from "../components/layout"
import SEO from "../components/seo"
import styles from "./index.module.css";
import headerStyles from "../components/header.module.css"
import Header from "../components/header";
import Heading from "../components/heading"
import Footer from "../components/footer";
import SocialMedia from "../components/socialMedia";

const IndexPage = () => (
    <Layout>
        <div
            className={styles.block}
        >
        <SEO title="Homepage"/>
        <SocialMedia colour={'#fff'}/>
        <div
            className={headerStyles.header}
        >
            <Header
                headerTextColour={designSystem.palette.white}
                backgroundColour={designSystem.palette.transparent}
                smallHeadingColour={designSystem.palette.white}
            />
        </div>
            <Heading colour={designSystem.palette.white} type={'largeHeading'}/>
            <div
                className={styles.content}
            >
                <p>Our Lavender products are made on the premises using only the purest ingredients. Our quaint little
                    shop is located in the historic village of Bodalla on the south coast of NSW.</p>
            </div>
            <Footer />
        </div>
    </Layout>
);

export default IndexPage;