import React from 'react'
import {Facebook} from "react-feather";

import style from './socialMedia.module.css'

const SocialMedia = ({colour, fill}) => (
    <a
        href='https://www.facebook.com/pages/category/Gift-Shop/Bodalla-Lavender-Shop-307590765942030/'
        className={style.layout}
        style={{
            color: colour ? colour : 'hsla(270, 83%, 34%, 1)',
            border: `1px solid ${colour ? colour : 'hsla(270, 83%, 34%, 1)'}`,
        }}
    >
        <Facebook/>
    </a>
);

export default SocialMedia;