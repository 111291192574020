import React from "react"
import styles from "./footer.module.css";

const Footer = ({footerTextColour}) => (
        <div className={styles.footer}>
            <a
                href='https://twitter.com/john_folder'
                style={{
                    color: footerTextColour ? footerTextColour : '#fff'
                }}
               >
                &copy; John Folder 2019
            </a>
        </div>
);

export default Footer;